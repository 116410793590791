<template>
  <div
    ref="eventLayout"
    class="event-layout"
    :class="{
      'event-layout--no-scroll': overlayStore.eventImagesModalIsActive,
    }"
    @scroll="handleScroll"
  >
    <slot />
    <app-toast-wrapper />
  </div>
</template>

<script setup>
  import { useOverlayStore } from '~/stores/overlay'

  const overlayStore = useOverlayStore()

  const eventLayout = ref(null)
  const stylesAreApplied = ref(false)
  const initialHtmlStyle = reactive({})
  const initialBodyStyle = reactive({})

  const htmlStyleToSet = {
    'margin': '0',
    'padding': '0',
    'width': '100%',
    'height': '100vh',
    'overflow': 'hidden',
  }
  const bodyStyleToSet = {
    'margin': '0',
    'padding': '0',
    'width': '100%',
    'height': '100vh',
    'overflow': 'hidden',
    'background': 'black',
    'position': 'fixed',
    'overscroll-behavior': 'none',
  }

  function storeInitialStyles() {
    const htmlElement = document.querySelector('html')
    const bodyElement = document.querySelector('body')

    Object.keys(htmlStyleToSet).forEach(key => {
      initialHtmlStyle[key] = htmlElement.style[key]
    })

    Object.keys(bodyStyleToSet).forEach(key => {
      initialBodyStyle[key] = bodyElement.style[key]
    })
  }

  function setNewStyles() {
    const htmlElement = document.querySelector('html')
    const bodyElement = document.querySelector('body')

    Object.keys(htmlStyleToSet).forEach(key => {
      htmlElement.style[key] = htmlStyleToSet[key]
    })

    Object.keys(bodyStyleToSet).forEach(key => {
      bodyElement.style[key] = bodyStyleToSet[key]
    })

    stylesAreApplied.value = true
  }

  function resetStyles() {
    const htmlElement = document.querySelector('html')
    const bodyElement = document.querySelector('body')

    Object.keys(htmlStyleToSet).forEach(key => {
      htmlElement.style[key] = initialHtmlStyle[key]
    })

    Object.keys(bodyStyleToSet).forEach(key => {
      bodyElement.style[key] = initialBodyStyle[key]
    })

    stylesAreApplied.value = false
  }

  onMounted(() => {
    window.addEventListener('resize', handleWindowResize)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', handleWindowResize)
  })

  function handleScroll(event) {
    if (overlayStore.eventImagesModalIsActive) {
      return
    }

    if (event.target.scrollTop === 0) {
      return resetStyles()
    }
  }

  // The native navigation bar animation on mobile
  // is considered a resize event
  function handleWindowResize() {
    if (overlayStore.eventImagesModalIsActive) {
      return
    }

    if (eventLayout.value.scrollTop === 0) {
      return resetStyles()
    }

    if (stylesAreApplied.value) {
      return
    }

    storeInitialStyles()
    setNewStyles()
  }

  onBeforeUnmount(() => resetStyles())
</script>

<style lang="scss">
  .event-layout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
    background: black;
  }

  .event-layout--no-scroll {
    overflow-y: hidden;
  }
</style>
